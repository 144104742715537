import {BaseClient} from "./BaseClient";
import {TransmissionFeeType} from "./BuildingClient";

//Todo: replace with localDate
export class LocalDateOld {
    private date: Date;

    constructor(date: Date) {
        this.date = date;
    }

    toJSON() {
        return this.date.toISOString().substring(0, 10)
    }
}

interface Customer {
    name: string
    address: Address
    commercialContact: CommercialContact
}

interface CommercialContact {
    name: string
    phone1: string
    phone2?: string
    email: string
}

interface Contract {
    startDate: LocalDateOld,
    endDate: LocalDateOld,
    revenueShare: number,
}

interface Address {
    line1: string,
    line2?: string,
    city: string,
    postCode: string,
    county: string,
    country: string
}

interface Tariff {
    transmissionFeeType: TransmissionFeeType,
    supplierId: number,
    startDate: LocalDateOld,
    endDate: LocalDateOld,
    pricePerKWh: number
}

interface Building {
    name: string
    address: Address
    controlStrategy: ControlStrategy
    orgIds: string[]
}

interface ControlStrategy {
    bmsStrategyId: number,
    maxTotalDurationMinutes: number,
    maxRunDuration: number,
    maxNumEvents: number,
    saving: number,
    recoveryDuration: number,
    rampUpDownTime: number
}

export interface Meter {
    serialNumber: string,
    mpan: string,
    mopId: number,
}

export interface SubmissionModel {
    hubSpotDealId?: string
    customer: Customer
    contract: Contract
    building: Building
    tariff: Tariff
    meters: Array<Meter>
}
interface MeterApiModel{
    mpan: string,
    mopId: string,
}
export interface DealProperties {
    dealname: string,
    customer_address_city: string,
    customer_address_country: string,
    customer_address_county: string,
    customer_address_line_1: string,
    customer_address_line_2?: string,
    customer_address_postcode: string,
    customer_commercial_contact_email: string,
    customer_commercial_contact_name: string,
    customer_commercial_contact_phone_1: string,
    customer_commercial_contact_phone_2?: string,
    customer_name: string,
    contract_end_date: string,
    contract_oaktree_power_revenue_share: string,
    contract_start_date: string,
    building_address_city: string,
    building_address_country: string,
    building_address_county: string,
    building_address_line_1: string,
    building_address_line_2?: string,
    building_address_postcode: string,
    building_name: string,
    energy_tariff_end_date: string,
    energy_tariff_fee_type: string,
    energy_tariff_price_per_kw_in_gbp: string,
    energy_tariff_start_date: string,
    energy_tariff_supplier: string,
    meters : MeterApiModel[],
    potential_kw: string,
}

export interface Deal {
    id: string,
    properties: DealProperties
}

export interface BasicEntity {
    id: string | number,
    name: string
}

export type Supplier = BasicEntity 

export type Mop = BasicEntity 

export class FormClient{

    baseClient: BaseClient

    constructor(baseClient: BaseClient) {
        this.baseClient = baseClient
    }

    async postForm(data: SubmissionModel): Promise<any> {
        const api = '/api/onboard/fullDelivery'

        const response: Response = await this.baseClient.post( api, data);
        if (response.ok)
            return
        else
            throw new Error('Could not post data' + JSON.stringify(data))
    }

    async retrieveNewDeals(): Promise<Array<Deal>> {
        const api = '/api/onboard/deal?newOnly'
        return this.baseClient.getOk(api);
    }

    async retrieveSuppliers(): Promise<Array<Supplier>> {
        const api = '/api/onboard/supplier'
        return this.baseClient.getOk(api);
    }

    async retrieveMops(): Promise<Array<Mop>> {
        const api = '/api/onboard/mop'
        return this.baseClient.getOk(api);
    }
}
