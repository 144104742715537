import uatConfig from './uat'
// import prodConfig from './prod'
import {Config} from './config'

// To connect f/e to Local (using UAT auth)
export default {
    ...uatConfig,
    API_HOST: 'http://localhost:8080'
} as Config

// To connect f/e to UAT
/*
export default {
    ...uatConfig,
    API_HOST: 'https://uat.oaktreepower.co.uk/tooling',
} as Config
*/

// To connect f/e to Prod
/*
export default {
    ...prodConfig,
    API_HOST: 'https://oaktreepower.co.uk/tooling',
} as Config
*/
