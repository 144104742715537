import {Building} from "./BuildingStatus";
import MultiSelect, {SelectOption} from "./common/MuiSelect";

export interface MultipleBuildingInputProps {
    buildingsSource: Building[]
    onChange: (bs: Building[]) => void
    disabled?: boolean
    selected?: Building[]
}

export const MultipleBuildingInput = ({buildingsSource, onChange, disabled, selected}: MultipleBuildingInputProps) => {
    const result = buildingsSource.reduce(function(map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});


    function handleBuildingChange(selectedList: SelectOption[]) {
        // Beware: When the elements selection change, the Multiselect component reuses the array passed here as
        // selectedList. Here we pass through a new array every time, because the caller typically use the onChange
        // to update a React state, but React doesn't detect changes to the membership of an array when the array
        // is the same even if the members change.

        const multiSelectOptions: Building[] = selectedList.map(b => {
           return result[b.id]
        }) ;
        onChange([...multiSelectOptions])
    }

    function toSelectOption(b: Building) {
        return {id: `${b.id}`, label: b.name};
    }

    return <MultiSelect initialValue={(selected ?? []).map(toSelectOption)} options={buildingsSource.map(toSelectOption)} onChange={handleBuildingChange} data-testid='building-selector' id='building-selector' label={'Buildings'} disabled={disabled}/>
}
