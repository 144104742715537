import {BaseClient} from "./BaseClient";
import {ObjectWithId} from "../components/common/CrudTable";
import {Result} from "./Result";

export interface Role {
    id: string
    name: string
}

export interface Organisation {
    id: string
    name: string
}

export interface FullUser extends ObjectWithId {
    name: string
    email: string
    roles: Role[]
    organisation: Organisation
}

export interface SimpleUser extends ObjectWithId {
    name: string
    email: string
}
export type EitherUser = FullUser|SimpleUser


export const isFullUser = (user: EitherUser) : user is FullUser  => (user as FullUser).organisation !== undefined;

interface ApiRole {
    id: string,
    name: string,
}

interface ApiOrganisation {
    id: string,
    name: string,
}

interface ApiUser {
    id: string,
    name: string,
    email: string,
    roles?: ApiRole[],
    org?: ApiOrganisation,
    lastLogin: string
}


export class UserClient {

    private baseClient: BaseClient

    constructor(baseClient: BaseClient) {
        this.baseClient = baseClient
    }


    async deleteUser(email: string): Promise<Result<null>> {
        return this.baseClient.deleteOk(`/api/user/${email}`);
    }


    async retrieveAllUsers(): Promise<EitherUser[]> {
        const resp = await this.baseClient.getOk<ApiUser[]>('/api/users')
        return resp.map(it => this.apiUseToEitherUser(it))
    }

    async retrieveUserByEmail(email: string): Promise<Result<EitherUser>> {
        const apiUser: Result<ApiUser> = await this.baseClient.getOkP(`/api/user/${email}`)
        return apiUser.map(apiUser => this.apiUseToEitherUser(apiUser)
        )
    }

    private apiUseToEitherUser(apiUser: ApiUser) {
        return (apiUser.org != undefined && apiUser.roles != undefined) ?
            ({
                queryableDataId: apiUser.email,
                name: apiUser.name,
                email: apiUser.email,
                roles: apiUser.roles.map(r => ({name: r.name, id: r.id})),
                organisation: {id: apiUser.org.id, name: apiUser.org.name}
            }) : ({
                queryableDataId: apiUser.email,
                name: apiUser.name,
                email: apiUser.email,

            });
    }

    async loadAllRoles(): Promise<Result<Role[]>> {
        const apiRoles: Result<ApiRole[]> = await this.baseClient.getOkP('/api/roles')
        return apiRoles.map(apiRoles => {
            return apiRoles.map(r => ({id: r.id, name: r.name}))
        })
    }

    async updateUser(user: EitherUser): Promise<Result<null>> {
       return isFullUser(user) ?await this.baseClient.putOk(`/api/user/${user.email}`, {name: user.name, roles: user.roles.map(r => r.id), orgId: user.organisation.id}) :
            await this.baseClient.putOk(`/api/user/${user.email}`, {name: user.name})
    }

    async createUser(user: EitherUser): Promise<Result<null>> {
        return isFullUser(user) ? await this.baseClient.postOk(`/api/user`, {
                name: user.name,
                email: user.email,
                roles: user.roles.map(r => r.id),
                orgId: user.organisation.id
            })
            : await this.baseClient.postOk(`/api/user`, {name: user.name, email: user.email})
    }

    async loadAllOrganisations(): Promise<Result<Organisation[]>> {
        const apiOrganisations: Result<ApiOrganisation[]> = await this.baseClient.getOkP('/api/org')
        return apiOrganisations.map(apiOrganisation => {
            return apiOrganisation.map(o => ({id: o.id, name: o.name}))
        })
    }

    async createOrg(name: string): Promise<Result<null>> {
        return this.baseClient.postOk(`/api/org`, {name: name});
    }

    async updateOrg(org: Organisation): Promise<Result<null>> {
        return this.baseClient.putOk(`/api/org/${org.id}`, {name: org.name});
    }

    async deleteOrg(orgId: string): Promise<Result<null>> {
        return this.baseClient.deleteOk(`/api/org/${orgId}`);
    }

    async loadOrgById(orgId: string) : Promise<Result<Organisation>>{
        return this.baseClient.getOkP(`/api/org/${orgId}`);
    }
}
