import React from "react";
import {Row} from "react-bootstrap";
import {BuildingInput} from "./BuildingInput";
import {Building} from "./BuildingStatus";
import {UnavailabilityCreateParameters} from "../client/EventScheduleClient";

interface UnavailabilityEventProps {
    setParameters: (ps: UnavailabilityCreateParameters) => void
    parameters?: UnavailabilityCreateParameters

    buildings: Building[]

    disabled: boolean
    allowBuildingChange: boolean
}

export const UnavailabilityEvent = ({
                                        buildings,
                                        disabled,
                                        allowBuildingChange,
                                        parameters,
                                        setParameters
                                    }: UnavailabilityEventProps) => {


    function handleSetBuilding(building: Building) {
        setParameters({buildingId: building.id})
    }

    return <div data-testid={'unavailabilityEvent'}>
        <Row>
            <BuildingInput className={'mb-3'} disabled={disabled || !allowBuildingChange} buildings={buildings}
                           onBuildingChange={handleSetBuilding}
                           building={buildings.find(b => b.id == parameters?.buildingId)}/>
        </Row>
    </div>
}
