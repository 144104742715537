import {primaryGreen} from "../Style";

export class EventStatus {
    status: string
    logicalStatus: string|null

    constructor(status: string, logicalStatus?: string|null) {
        this.status = status
        this.logicalStatus = logicalStatus === undefined ? null : logicalStatus
    }

    // See full fail then full success scenario: https://app.eraser.io/workspace/Z4Xnqn0XP47ava3XIzIh?origin=share
    createdAwaitingSchedulerToSendToDevice(): boolean { return this.status === 'logged' &&  this.logicalStatus === null}
    createdButFailedToSendToDevice(): boolean { return this.status === 'allbuildingsfailed' &&  this.logicalStatus === null }
    createdAndSuccessfullySentToDevice(): boolean { return this.status === 'senttodevice' &&  this.logicalStatus === null }

    // See early cancellation scenario: https://app.eraser.io/workspace/fflTi5jZ0jZ9PUOzWFJs?origin=share
    cancelledNeverSentToDevice(): boolean { return this.status === 'updatedlogged' && this.logicalStatus === 'deleted' }

    // See early update scenario: https://app.eraser.io/workspace/6mySGasI4GIeFnqSD3Ge?origin=share
    updatedNeverSentToDevice(): boolean { return this.status === 'updatedlogged' &&  this.logicalStatus === null}

    // See late update scenario: https://app.eraser.io/workspace/EnfMCH4mpJnFiPm0DmqW?origin=share
    // See update during event scenario: https://app.eraser.io/workspace/YIuBaYJXO975rIVITfmT?origin=share
    updatedAwaitingSchedulerToSendToDevice(): boolean { return this.status === 'updatedsent' && this.logicalStatus == null}

    // See late cancellation scenario https://app.eraser.io/workspace/IYIe2MPqFsDSGjcNFLSS?origin=share
    cancelledBeforeTheEventAwaitingRemovalFromDevice(): boolean {return this.status === 'updatedsent' && this.logicalStatus === 'deleted' }
    // See cancellation during the event scenario https://app.eraser.io/workspace/HpBWCGlB2dQKjZYvX9X8
    cancelledDuringTheEventAwaitingRemovalFromDevice(): boolean {return this.status === 'updatedsent' && this.logicalStatus === 'stopped' }
    cancelledAndSuccessfullyRemovedFromDevice(): boolean {return this.status === 'senttodevice' && this.logicalStatus === 'deleted' }

    cancelledDuringEventAndSuccessfullyRemovedFromDevice(): boolean {return this.status === 'senttodevice' && this.logicalStatus === 'stopped' }
    unavailability(): boolean {return this.status === 'unavailability'}

    //todo: rework colour
    toColour(): string | undefined {
        if (this.createdAwaitingSchedulerToSendToDevice() ||
            this.cancelledBeforeTheEventAwaitingRemovalFromDevice() ||
            this.cancelledDuringTheEventAwaitingRemovalFromDevice() ||
            this.updatedNeverSentToDevice() ||
            this.updatedAwaitingSchedulerToSendToDevice()
        ) return 'grey'
        else if (this.createdAndSuccessfullySentToDevice()) return primaryGreen
        else if (this.cancelledDuringEventAndSuccessfullyRemovedFromDevice()) return primaryGreen
        else if (this.unavailability()) return 'purple'
        else return 'red'
    }

    explain(): string | undefined {
        if (this.createdAwaitingSchedulerToSendToDevice()) return "Event schedule recorded. The scheduler hasn't sent it to the device (yet)"
        else if (this.createdButFailedToSendToDevice()) return 'Event schedule recorded, but the scheduler has failed to send it to the device'
        else if (this.createdAndSuccessfullySentToDevice()) return 'Event schedule recorded and successfully sent to the device'
        else if (this.cancelledNeverSentToDevice()) return 'Event schedule was cancelled before being sent to the device'
        else if (this.updatedNeverSentToDevice()) return "Event schedule update has been recorded. The scheduler hasn't sent it to the device (yet)"
        else if (this.cancelledBeforeTheEventAwaitingRemovalFromDevice()) return "Event schedule cancellation before the event has been recorded. The scheduler hasn't sent it to the device (yet)"
        else if (this.cancelledDuringTheEventAwaitingRemovalFromDevice()) return "Event schedule cancellation during the event has been recorded. The scheduler hasn't sent it to the device (yet)"
        else if (this.cancelledAndSuccessfullyRemovedFromDevice()) return 'Event schedule cancellation has been recorded and successfully sent to the device'
        else return undefined
    }
}
