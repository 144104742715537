import {DataType} from "./BaseClient";


export interface ApiTransport {
    post: (url: string, data?: object, token?: string|null, dataType?: DataType) => Promise<Response>
    delete: (url: string, token?: string|null,) => Promise<Response>
    patch: (url: string, data: object, token?: string|null,) => Promise<Response>
    put: (url: string, data?: object, token?: string|null,) => Promise<Response>
    get: (url: string, token?: string|null, header?: HeadersInit) => Promise<Response>
}



export class FetchTransport implements ApiTransport {

    async delete(url = '', token?: string | null,): Promise<Response> {
        // Default options are marked with *
        const headers: HeadersInit = {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
        };
        if (token) {
            (headers['Authorization'] = `Bearer ${token}`);
        }

        return fetch(url, {
            method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'default',
            credentials: 'same-origin', // include, *same-origin, omit
            headers: headers,
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        });
    }

    async post(url = '',  data = {}, token?: string | null, dataType?: DataType): Promise<Response> {
        const resolvedDataType = dataType ?? DataType.JSON
        // Default options are marked with *
        const headers: HeadersInit = resolvedDataType == DataType.JSON ?
            {
                'Content-Type': 'application/json',
            } : {}

        if (token) {
            (headers['Authorization'] = `Bearer ${token}`);
        }

        function body(): string | FormData {
            if (resolvedDataType == DataType.JSON) {
                return JSON.stringify(data)
            } else {
                const formData = new FormData()
                formData.append('file', data as File)
                return formData
            }
        }
        return fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'default', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: headers,
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: body() // body data type must match "Content-Type" header
        });
    }

    async put(url = '', data: object = {}, token?: string | null,): Promise<Response> {
        // Default options are marked with *
        const headers: HeadersInit = {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
        };
        if (token) {
            (headers['Authorization'] = `Bearer ${token}`);
        }

        return fetch(url, {
            method: 'PUT', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'default', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: headers,
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data)
        });
    }

    async patch(url = '',  data = {}, token?: string | null,): Promise<Response> {
        // Default options are marked with *
        const headers: HeadersInit = {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
        };
        if (token) {
            (headers['Authorization'] = `Bearer ${token}`);
        }

        return fetch(url, {
            method: 'PATCH', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'default', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: headers,
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
    }

    async get(url = '',  token?: string | null, headers?: HeadersInit): Promise<Response> {
        // Default options are marked with *
        const requestHeaders: HeadersInit = headers ?? {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
        };
        if (token) {
            (requestHeaders['Authorization'] = `Bearer ${token}`);
        }

        return fetch(url, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'default', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: requestHeaders,
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        });
    }
}
