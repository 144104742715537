import React from "react";
import {Row} from "react-bootstrap";
import {NumberField} from "./OnboardingForm";
import {MfrrCreateParameters, WithId} from "../client/EventScheduleClient";
import {Building} from "./BuildingStatus";
import {MultipleBuildingInput} from "./MultipleBuildingInput";

interface MfrrBiddingWindowEventProps {
    setParameters: (ps: MfrrCreateParameters) => void
    parameters: MfrrCreateParameters
    buildings: Building[]
    disabled: boolean
    allowBuildingChange: boolean
}

export const MfrrBiddingWindowEvent = ({
                                           setParameters,
                                           parameters,
                                           buildings,
                                           disabled,
                                           allowBuildingChange
                                       }: MfrrBiddingWindowEventProps) => {


    function handleSetParameter(name:string) {
        return function (e: any) {
            parameters[name] = parseInt(e.target.value)
            setParameters({... parameters})
        }
    }

    function handleSetBuilding(buildings: Building[]) {
        parameters.buildings = [...buildings.map(b => ({id: b.id}))]
        setParameters({... parameters})
    }

    function toBuilding(building: WithId) {
        return buildings.find(b => b.id==building.id)!
    }

    return <div data-testid={'biddingWindow'}>
        <Row className="mb-3">
            <MultipleBuildingInput disabled={disabled || !allowBuildingChange} buildingsSource={buildings} onChange={handleSetBuilding}
                                   selected={parameters.buildings.map(toBuilding)} />
        </Row>
        <Row>
            {<NumberField disabled={disabled} displayName={'Bid Price In Euros Per Megawatt-Hours'} min={"0"} required={true} handleChange={handleSetParameter('bidPriceInEurosPerMegawattHours')} name={'bidPriceInEurosPerMegawattHours'} testId={'bidPriceInEurosPerMegawattHours'} value={parameters.bidPriceInEurosPerMegawattHours}/>}
        </Row>
        <Row>
            {<NumberField disabled={disabled} displayName={'Bid Quantity In Megawatts'} min={"1"} required={true} handleChange={handleSetParameter('bidQuantityInMegawatts')} name={'bidQuantityInMegawatts'} testId={'bidQuantityInMegawatts'} value={parameters.bidQuantityInMegawatts}/>}
            {<NumberField disabled={disabled} displayName={'Max Activations'} min={"1"} required={true} handleChange={handleSetParameter('maxActivations')} name={'maxActivations'} testId={'maxActivations'} value={parameters.maxActivations}/>}
        </Row>
    </div>
}
