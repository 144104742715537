import {Config} from "./config";

export default {
    SSO_AUTH_CONFIG_TENANT_NAME: 'opldev',
    SSO_AUTH_CONFIG_CLIENT_ID: '9796a42c-2be1-4254-a577-7217a38d8862',
    ENABLE_FEATURE: true,
    API_HOST: "/tooling",
    AUTH0_DOMAIN: 'dev-tf6frz2db0stbq78.us.auth0.com',
    AUTH0_CLIENT_ID: 'VAS41NMVSrenoPpr6zWc7AiGdz8glhnL',
} as Config
