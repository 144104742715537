import React, {useEffect} from "react";
import {Row} from "react-bootstrap";
import {Constraint, DeviceCreateParameters} from "../client/EventScheduleClient";
import {BuildingInput} from "./BuildingInput";
import {Building} from "./BuildingStatus";
import {BuildingEventConstraints} from "./BuildingEventConstraint";

interface DeviceEventProps {
    buildings: Building[]
    eventType: string

    constraint: Constraint | null

    disabled: boolean
    allowBuildingChange: boolean

    setParameters: (ps: DeviceCreateParameters) => void
    parameters?: DeviceCreateParameters
}

export const DeviceEvent = ({
                                buildings,
                                eventType,
                                constraint,
                                disabled,
                                allowBuildingChange,
                                setParameters,
                                parameters
                            }: DeviceEventProps) => {

    useEffect(() => {
        if (parameters)
            setParameters({buildingId: parameters.buildingId, eventType})

    }, [eventType]);

    function handleSetBuilding(building: Building) {
        if (parameters) {
            parameters['buildingId'] = building.id
            setParameters({...parameters})
        } else
            setParameters({buildingId: building.id, eventType})

    }

    return <div data-testid={'deviceEvent'}>
        <Row>
            <BuildingInput className={'mb-3'} disabled={disabled || !allowBuildingChange} buildings={buildings}
                           onBuildingChange={handleSetBuilding}
                           building={buildings.find(b => b.id==parameters?.buildingId)}/>
        </Row>
        {constraint &&
            <BuildingEventConstraints constraint={constraint}/>}
    </div>
}
