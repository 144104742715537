import {EventSchedule, Schedule} from "../components/EventSchedule";
import {BaseClient} from "./BaseClient";
import {DateTime} from "../time/DateTime";
import {EventStatus} from "../model/EventStatus";
import {Failure, Result, Success} from "./Result";
import {parseResponseApiErrors, safeJson} from "./ApiErrorsParser";

export interface NewEvent {
    type: EventType
    startTime: DateTime
    endTime: DateTime
    buildingId: number
}

export interface WithEventId {
    eventId: string
}

export interface Event {
    id: string
    start: DateTime
    end: DateTime
    buildingId: number
    title: string
}

export class EventWithDetails {
    constructor(
        public id: string,
        public start: DateTime,
        public end: DateTime,
        public eventType: EventType,
        public eventStatus: EventStatus,
        public title: string,
        public parameters: CalendarEventCreationParameters,
        public stopTime?: DateTime,
    ) {}

    actualEndTime(): DateTime {
        return this.eventStatus.cancelledDuringEventAndSuccessfullyRemovedFromDevice() && this.stopTime
            ? this.stopTime
            : this.end;
    }

    static fromObject(obj: {
        id: string,
        start: DateTime,
        end: DateTime,
        eventType: EventType,
        eventStatus: EventStatus,
        title: string,
        stopTime?: DateTime,
        parameters: CalendarEventCreationParameters
    }): EventWithDetails {
        return new EventWithDetails(
            obj.id,
            obj.start,
            obj.end,
            obj.eventType,
            obj.eventStatus,
            obj.title,
            obj.parameters,
            obj.stopTime,
        );
    }
}

export type Unavailability = EventWithDetails
export type UpdateEvent = NewEvent & WithEventId


export interface NewUpdateEvent extends WithEventId {
    start?: DateTime
    end?: DateTime
    parameters?: CalendarEventUpdateParameters,
}

export interface ApiValidatableEvent {
    id?: string,
    start: string,
    end: string,
    buildingIds: number[],
    type: string,
}

export type CalendarEventType = 'unavailability' | 'device' | 'mfrr_bidding_window'
export interface MfrrCreateParameters extends MfrrUpdateParameters{
    buildings: WithId[],
}

export interface MfrrUpdateParameters {
    bidPriceInEurosPerMegawattHours: number
    bidQuantityInMegawatts: number
    maxActivations: number
}

export interface DeviceCreateParameters extends DeviceUpdateParameters {
    buildingId: number,
}

export interface DeviceUpdateParameters {
    eventType: string,
}

export interface UnavailabilityCreateParameters extends UnavailabilityUpdateParameters {
    buildingId: number,
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UnavailabilityUpdateParameters { }

export type CalendarEventUpdateParameters = MfrrUpdateParameters | DeviceUpdateParameters | UnavailabilityUpdateParameters
export type CalendarEventCreationParameters = MfrrCreateParameters | DeviceCreateParameters | UnavailabilityCreateParameters

export interface ApiCreationValidatableCalendarEvent {
    start: string,
    end: string,
    calendarEventType: CalendarEventType,
    parameters: CalendarEventCreationParameters
}

export interface Interval {
    start: DateTime
    end: DateTime
}

export interface Constraint {
    dailyEvents: Interval[]
    maxEvents: number
    maxEventDuration: number
    recoveryTime: number
    totalDailyRunTime: number
}
export interface WithId {
    id: number
}


interface ApiParameters {
    // mandatory for device & unavailability, missing for mFRR
    buildingId?: number

    // optional for device, missing for rest
    eventType?: string
    stop?: string
    status?: string
    logicalStatus?: string

    // mandatory for mFRR, missing for rest
    buildings?: WithId[]
    bidDuration?: string
    bidPriceInEurosPerMegawattHours?: number
    bidQuantityInMegawatts?: number
    maxActivations?: number
}
interface ApiEvent {
    id: string
    title: string
    start: string
    end: string
    calendarEventType: CalendarEventType
    parameters: ApiParameters
}

export interface TestScheduleError {
    messages: string[]
}

interface OplServiceError {
    message: string
}

interface ApiInterval {
    start: string
    end: string
}

export class EventType {
    type: CalendarEventType;
    displayName: string;

    constructor(type: CalendarEventType, displayName: string) {
        this.type = type;
        this.displayName = displayName;
    }

    id():string { return this.type + "-" + this.displayName }

}

export interface EventPerformance {
    eventId: number
    shouldThePerformanceWorryAPerson: boolean
}

interface ApiControlStrategy {
    maxTotalDurationMinutes: number,
    maxRunDuration: number,
    maxNumEvents: number,
    saving: number,
    recoveryDuration: number,
    rampUpDownTime: number,
}

interface ApiEventPerBuildingValidationInfo {
    controlStrategy: ApiControlStrategy
    otherEventsOnTheDay: ApiInterval[]
}


interface ApiEventValidationInfo {
    eventPerBuildingValidationInfos: ApiEventPerBuildingValidationInfo[]
    existingEventWindow?: ApiInterval
}

export class EventScheduleClient {
    private baseClient: BaseClient;

    constructor(baseClient: BaseClient) {
        this.baseClient = baseClient;
    }

    public getAllEventSchedules(): Promise<EventSchedule[]> {
        return this.baseClient.getOk('/api/event/schedule')
    }

    public scheduleEvents(events: Schedule[]): Promise<Result<null>> {
        return this.baseClient.postOk("/api/event/schedule/batch", events)
    }

    public async getEventTypes(): Promise<EventType[]> {
        return (await this.baseClient.getOk<{ displayName:string, type:CalendarEventType }[]>('/api/calendarEvent/creationEventTypes').then(types => types.map(et => new EventType(et.type, et.displayName))))
    }

    public async getEventPerformance(start: DateTime, end: DateTime): Promise<Result<EventPerformance[]>> {
        return (await this.baseClient.getOkP<EventPerformance[]>(`/api/event/eventPerformance/start/${start.toISODate()}/end/${end.toISODate()}`))
    }

    private async parseCreateOrUpdateResponse(response: Response): Promise<{ messages: string[] } | null> {
        if (response.ok) return null
        else {
            const errorMessage: OplServiceError | string = await safeJson<OplServiceError>(response)
            if (typeof errorMessage === 'string') {
                return {messages: [`Failure response ${response.status}: ${errorMessage}`]}
            } else {
                const possiblyJsonMarshalledMessages = errorMessage.message
                try {
                    const messages: string[] = JSON.parse(possiblyJsonMarshalledMessages)
                    return {messages}
                } catch (_) {
                    return {messages: [possiblyJsonMarshalledMessages]}
                }
            }
        }
    }

    public async createCalendarEvent(event: ApiCreationValidatableCalendarEvent): Promise<Result<null>> {
        const response = await this.baseClient.post('/api/calendarEvent', event)
        const result = await this.parseCreateOrUpdateResponse(response);
        return (result == null) ? new Success(null) : new Failure(result.messages.join(","))
    }

    async scheduleTestEvent(buildingId: number): Promise<TestScheduleError | null> {
        const queryParams = new URLSearchParams({testForBuildingId: buildingId.toString()})
        const response = await this.baseClient.post('/api/event?' + queryParams.toString())
        return this.parseCreateOrUpdateResponse(response);
    }

    public async getEvents(start: DateTime, end: DateTime, buildingIds?: number[]): Promise<EventWithDetails[]> {
        const rangeParams: { start: string; end: string } = {start: start.toUTC().toISO(), end: end.toUTC().toISO()}
        const queryParams = new URLSearchParams(rangeParams)
        if (buildingIds) {
            buildingIds.forEach(bid => {
                queryParams.append('buildingId', bid.toString())
            })
        }
        const response: ApiEvent[] = (await this.baseClient.getOk<ApiEvent[]>('/api/calendarEvent?' + queryParams.toString()))
            .map(a => {
                if (a.id == undefined || a.end == undefined || a.start == undefined || a.title == undefined || a.parameters == undefined) throw Error('Value from api did not meet contract')
                return a
            })

        return response.map(({
                                 id,
                                 title,
                                 start,
                                 end,
                                 calendarEventType,
                                 parameters
                             }: ApiEvent) => {
            const startDateTime = DateTime.fromISO(start)
            const endDateTime = DateTime.fromISO(end)
            const stopTime = parameters.stop ? DateTime.fromISO(parameters.stop) : undefined

            const [mungedEventType, eventStatus] = this.mungedEventTypeAndStatus(calendarEventType, parameters?.eventType, parameters?.status, parameters?.logicalStatus)

            let eventParams: CalendarEventCreationParameters;
            switch (calendarEventType) {
                case "device":
                    eventParams = {
                        buildingId: parameters.buildingId!,
                        eventType: parameters.eventType!
                    } as DeviceCreateParameters
                    break
                case "unavailability":
                    eventParams = {
                        buildingId: parameters.buildingId!
                    } as UnavailabilityCreateParameters
                    break

                case "mfrr_bidding_window":
                    eventParams = {
                        bidPriceInEurosPerMegawattHours: parameters.bidPriceInEurosPerMegawattHours!,
                        bidQuantityInMegawatts: parameters.bidQuantityInMegawatts!,
                        maxActivations: parameters.maxActivations!,
                        buildings: parameters.buildings!
                    } as MfrrCreateParameters
                    break

                default:
                    throw new Error(`Unknown calendar event type ${calendarEventType}`)
            }

            return EventWithDetails.fromObject({
                id,
                start: startDateTime,
                end: endDateTime,
                stopTime: stopTime,
                title,
                eventType: mungedEventType,
                eventStatus: eventStatus,
                parameters: eventParams,
            })
        })
    }

    private mungedEventTypeAndStatus(calendarEventType: CalendarEventType, eventType?: string, status?: string, logicalStatus?: string):[EventType, EventStatus] {
        if (calendarEventType === 'unavailability')
            return[ new EventType('unavailability', 'Unavailability'), new EventStatus('unavailability')]
        else if (calendarEventType === 'mfrr_bidding_window')
            return[ new EventType('mfrr_bidding_window', 'mFRR Bidding Window'), new EventStatus('senttodevice')]
        else
            return [
                new EventType(calendarEventType, eventType ?? 'Event'),
                new EventStatus(status ?? 'senttodevice', logicalStatus)
            ]
    }

    public async updateEvent(event: NewUpdateEvent): Promise<Result<null>> {
        const response = await this.baseClient.patch(`/api/calendarEvent/${event.eventId}`,
            {
                start: event.start?.toISO(),
                end: event.end?.toISO(),
                parameters: event.parameters
            })
        const parsedErrorResponse = await this.parseCreateOrUpdateResponse(response)
        return (parsedErrorResponse == null) ? new Success(null) : new Failure(parsedErrorResponse.messages.join(","))
    }

    public async deleteEvent(event: WithEventId): Promise<Result<null>> {
        const response = await this.baseClient.delete(`/api/calendarEvent/${event.eventId}`)
        const parsedErrors = await parseResponseApiErrors(response)
        return (parsedErrors == null) ? new Success(null) : new Failure(parsedErrors.map(e => e.message).join(", "))
    }

    public async retrieveValidationInfo(event: NewEvent | UpdateEvent): Promise<Constraint> {
        const apiEvent: ApiValidatableEvent = ({
            id: event['eventId'],
            start: event.startTime.toISO(),
            end: event.endTime.toISO(),
            buildingIds: [event.buildingId],
            type: event.type.displayName
        })
        const response = await this.baseClient.post(`/api/event?onlyRetrieveValidationInformation`, apiEvent)
        if (response.ok) {
            const validationInfo: ApiEventValidationInfo = await response.json()
            const controlStrategy = validationInfo.eventPerBuildingValidationInfos[0].controlStrategy;
            const dailyEvents = validationInfo.eventPerBuildingValidationInfos[0].otherEventsOnTheDay
                .map(i => ({
                    start: DateTime.fromISO(i.start),
                    end: DateTime.fromISO(i.end)
                }))
            return {
                dailyEvents: dailyEvents,
                maxEventDuration: controlStrategy.maxRunDuration,
                maxEvents: controlStrategy.maxNumEvents,
                recoveryTime: controlStrategy.recoveryDuration,
                totalDailyRunTime: controlStrategy.maxTotalDurationMinutes
            }
        } else {
            throw new Error('Failed to retrieve event validation information')
        }
    }
}
