import {getTimeZones} from "@vvo/tzdb";


export interface Timezone {
    name: string;
    rawOffsetInMinutes: number;
    currentTimeFormat: string
}

export class Timezone {

    static getAll(): Timezone[] {
        return getTimeZones().map(z => ({
            name: z.name,
            rawOffsetInMinutes: z.rawOffsetInMinutes,
            currentTimeFormat: z.currentTimeFormat
        }));
    }

    static getUserTimezone() {
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return getTimeZones().find(z =>
            z.name.includes(userTimezone)) ?? {
            name: 'Europe/London',
            rawOffsetInMinutes: 0,
            currentTimeFormat: "+00:00 Greenwich Mean Time - London, Birmingham, Liverpool, Glasgow"
        };
    }
}