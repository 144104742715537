import {AsyncComponentP} from "../common/AsyncComponent";
import {Organisation, UserClient} from "../../client/UserClient";
import {useParams} from "react-router-dom";
import {ErrorMessage} from "../common/ErrorMessage";
import { Result} from "../../client/Result";
import {CrudForm} from "../common/CrudForm";
import {admin} from "../../Routes";

interface EditOrgAdminPageProps {
    userClient: UserClient
}

export const EditOrgAdminPage = ({userClient}: EditOrgAdminPageProps) => {

    const {orgId} = useParams()

    async function loaderFunction(orgId: string): Promise<Result<Organisation>> {
        return userClient.loadOrgById(orgId);
    }

    return (orgId ? <AsyncComponentP loaderFunction={() => loaderFunction(orgId)} dataPropName={'initialData'}>
        <EditOrgForm initialData={{id:'', name:''}} onSubmit={org => userClient.updateOrg(org)}/>
    </AsyncComponentP> : <ErrorMessage error={Error(`Org is ${orgId}`)}/>)
}


interface EditOrgFormProps{
    initialData: Organisation
    onSubmit: (org: Organisation) => Promise<Result<null>>
}

export function EditOrgForm({initialData, onSubmit} : EditOrgFormProps){


    function handleSubmit(org: Organisation): Promise<Result<null>> {
        if (org.name && org.id) {
            return onSubmit(org)
        } else {
            throw new Error('Submitting user edit without original fields')
        }
    }


    return <CrudForm redirectPath={admin.path} data={initialData} fields={[
        {key: `name`, label: 'Name', type: 'string', required:true},
    ]} onSubmit={handleSubmit}/>
}
