import {UserInfo} from "../AuthContext";
import {ApiTransport} from "./ApiTransport";
import { Failure, Result, Success } from "./Result";

export enum DataType {
    JSON = 'json',
    FORM_DATA = 'formData',
}

export class BaseClient {

    transport: ApiTransport
    userSupplier?: () => Promise<UserInfo>
    host: string

    constructor(transport: ApiTransport, userSupplier?: () => Promise<UserInfo>, host = '') {
        this.transport = transport
        this.userSupplier = userSupplier
        this.host = host
    }

    private url = (path: string) => this.host + path


    private forbiddenResponse = Promise.resolve(new Response('Forbidden', {
        status: 403,
        statusText: 'Forbidden'
    }));


    public async delete(path: string): Promise<Response> {
        if (!this.userSupplier) {
            return this.forbiddenResponse
        }
        const user = await this.userSupplier()
        return this.transport.delete(this.url(path), user.token);
    }
    
    public async post(path: string, data?: object, dataType?: DataType): Promise<Response> {
        if (!this.userSupplier) {
            return this.forbiddenResponse
        }
        const user = await this.userSupplier()
        return this.transport.post(this.url(path), data, user.token, dataType);
    }

    public async patch(path: string, data: object): Promise<Response> {
        if (!this.userSupplier) {
            return this.forbiddenResponse
        }
        const user = await this.userSupplier()
        return this.transport.patch(this.url(path), data, user.token);
    }

    public async put(path: string, data?: object): Promise<Response> {
        if (!this.userSupplier) {
            return this.forbiddenResponse
        }
        const user = await this.userSupplier()
        return this.transport.put(this.url(path), data, user.token);
    }

    public async putOk(path: string, data?: object): Promise<Result<null>> {
        const response = await this.put(path, data);
        if (response.ok)
            return new Success(null)
        else return new Failure(`Could not PUT data to ${path} status was:${response.statusText}`)
    }

    public async get(path: string, header?: HeadersInit): Promise<Response> {
        if (!this.userSupplier) {
            return this.forbiddenResponse
        }
        const user = await this.userSupplier();
        return this.transport.get(this.url(path), user.token, header);
    }


    public async patchOk(path: string, data: object): Promise<Result<null>> {
        const response = await this.patch(path, data);
        if (response.ok)
            return new Success(null)
        else return new Failure(`Could not PATCH data to ${path} status was:${response.statusText}`)
    }

    /**
     * @deprecated The method should not be used.
     * Use getOkP instead
     */
    public async getOk<T>(api: string): Promise<T> {
        const response = await this.get(api);
        if (response.ok)
            return response.json()
        else
            throw new Error(`Could not GET data from ${this.host + api}`)
    }

    public async postOk(path: string, data: object, dataType?: DataType): Promise<Result<null>> {
        const response = await this.post(path, data, dataType);
        if (response.ok)
            return new Success(null)
        else {
            const responseBody = await response.text()
            return new Failure(responseBody)
        }
    }


    public async getOkP<T>(api: string): Promise<Result<T>> {
        const response = await this.get(api);
        if (response.ok) {
            const json = await response.json()
            return new Success<T>(json)
        } else {
            return new Failure(await response.text())
        }
    }

    public async deleteOk(path: string): Promise<Result<null>> {
        const response = await this.delete(path);
         if (response.ok) {
            return new Success(null)

         } else {
            const responseText = await response.text()
            const errorMessage = responseText == "" ? response.statusText : responseText
            return new Failure(errorMessage)
         }
    }

    public async getOkCsv(api: string): Promise<Result<string>> {
        const response = await this.get(api, { 'Accept': 'text/csv', });
        if (response.ok) {
            const csv = await response.text();
            return new Success(csv)
        } else {
            return new Failure(`Could not GET data from ${this.host + api}`)
        }
    }
}
