import {BaseClient} from "./BaseClient";
import { Result } from "./Result";

export interface RequestCancellationBody {
    eventId: string
}

export class NotificationClient {

    baseClient: BaseClient

    constructor(baseClient: BaseClient) {
        this.baseClient = baseClient
    }

    async requestCancellation(body: RequestCancellationBody): Promise<Result<null>> {
        return this.baseClient.postOk('/api/notification/requestEventCancellation', body);
    }

}
