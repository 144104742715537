import {DateTime as LuxonDateTime} from 'luxon'
import {DateTimeOptions, DateTimeUnit} from "luxon/src/datetime";
import {DurationLike} from "luxon/src/duration";
import {Zone} from "luxon/src/zone";

export class DateTime {
    private readonly dateTime: LuxonDateTime;

    get invalidReason(): string {
        return this.dateTime.invalidReason ?? ''
    }

    private constructor(value: LuxonDateTime, throwOnInvalid = true) {
        if (throwOnInvalid && !value.isValid) {
            throw Error(`Invalid Date: ${value.invalidReason} - ${value.invalidExplanation}`)
        }
        this.dateTime = value
    }

    raw(): LuxonDateTime{
        return this.dateTime
    }

    static now(): DateTime {
        return new DateTime(LuxonDateTime.now())
    }

    static fromISODateString(text: string, zone = 'Europe/London'): DateTime {
        const value = LuxonDateTime.fromFormat(text, "yyyy-MM-dd", {zone: zone});
        return new DateTime(value)
    }

    static fromISO(value: string, opts: DateTimeOptions = {zone: 'Europe/London'}): DateTime {
        return new DateTime(LuxonDateTime.fromISO(value, opts))
    }

    static FromISOOrError(value: string, opts?: DateTimeOptions): DateTime | string {
        try{
            return new DateTime(LuxonDateTime.fromISO(value, opts))
        } catch(e){
            return `couldnt parse ${value}`
        }
    }

    static fromJSDate(value: Date, options?: { zone?: string | Zone }): DateTime {
        return new DateTime(LuxonDateTime.fromJSDate(value, options))
    }

    static local(): DateTime {
        return new DateTime(LuxonDateTime.local())
    }

    static fromSeconds(date: number) {
        return new DateTime(LuxonDateTime.fromSeconds(date))
    }

    static fromRaw(value: LuxonDateTime, throwOnError = true): DateTime {
        return new DateTime(value, throwOnError)
    }

    toLondonTimeZone(): DateTime {
        return new DateTime(this.dateTime.setZone("Europe/London"))
    }

    toISODate(): string {
        return this.dateTime.toISODate()!
    }


    toUTC(): DateTime {
        return new DateTime(this.dateTime.toUTC())
    }

    toISO(): string {
        return this.dateTime.toISO()!
    }

    toInputValue(): string {
        const toISO = this.toISO()
        if (toISO == null) throw new Error('date time was not valid' + this.invalidReason)
        return toISO.substring(0, 16)
    }

    plus(duration: DurationLike): DateTime {
        return new DateTime(this.dateTime.plus(duration));
    }

    toMillis(): number {
        return this.dateTime.toMillis()
    }

    toFormat(format: string) {
        return this.dateTime.toFormat(format)
    }

    toUnixInteger(): number {
        return this.dateTime.toUnixInteger()
    }

    endOf(unit: DateTimeUnit) {
        return new DateTime(this.dateTime.endOf(unit))
    }

    minus(duration: DurationLike) {
        return new DateTime(this.dateTime.minus(duration));
    }

    diffMinutes(other: DateTime): number{
        const minutes = this.dateTime.diff(other.dateTime,['minutes']).toObject().minutes;
        if(minutes == undefined) throw new Error(`diffing ${this.dateTime} and ${other.dateTime} is undefined`)
        return minutes
    }

    startOf(unit: DateTimeUnit) {
        return new DateTime(this.dateTime.startOf(unit));
    }

    get minute() {
        return this.dateTime.minute
    }
    get hour() {
        return this.dateTime.hour
    }
    get second() {
        return this.dateTime.second
    }
}
