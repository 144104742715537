import {BaseClient} from "./BaseClient";

export type Granularity = 'PT15S' | 'PT1M' | 'PT5M' | 'PT30M'

export interface MeterApi {
    mpan: string
    description: string
    readingsGranularities: Granularity[]
}


export class MeterClient {
    baseClient: BaseClient

    constructor(baseClient: BaseClient) {
        this.baseClient = baseClient
    }

    async retrieveMeters(): Promise<MeterApi[]> {
        return this.baseClient.getOk(`/api/meter`);
    }
}
